body,
html,
#root,
.page{
  height: 100%;
}

.page {
  &.prima {
    .template-header-bar {
      display: flex;
      justify-content: center;
      flex-direction: row;
      align-items: center;
      background-color: white;
      padding: 0.5rem 1rem;
      box-shadow: 0px -3px 10px 0px grey;
      .logo {
        width: 120px;
      }
    }
    .steps-counter-container {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 786px) {
  .hidden-mobile {
    display: none;
  }
}
